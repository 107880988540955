import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Container, Year } from "./milestoneTextLeft"

const MilestoneTextRight: React.FC = props => {
  const { year, title, text, link, image, i } = props as any
  return (
    <Container>
      <ImageContainer>
        <Year>{year}</Year>
        {/*// @ts-ignore*/}
        {image.fluid ? <Img fluid={image.fluid} /> : null}
      </ImageContainer>
      <ContentWrapper>
        <Title dangerouslySetInnerHTML={{ __html: title.html }} />
        <Text dangerouslySetInnerHTML={{ __html: text.html }} />
      </ContentWrapper>
    </Container>
  )
}

export default MilestoneTextRight

const ContentWrapper = styled.div`
  position: relative;
  border-left: 1px solid var(--primary-text);
  padding: 80px 8px 80px 40px;
  text-align: left;
  max-width: 600px;
  @media (max-width: 720px) {
    padding: 8px;
    text-align: center;
  }
`

const Title = styled.div`
  margin-top: 1rem;
  font-family: "Moderat Bold", sans-serif;
  text-transform: capitalize;
  color: var(--primary-text);
`
const Text = styled.div`
  p {
    color: var(--primary-text);
    margin-bottom: 1rem;
  }
`

const ImageContainer = styled.div`
  position: relative;
  padding: 80px 40px 80px 8px;
  justify-self: flex-end;
  .gatsby-image-wrapper {
    width: 400px;
    height: 250px;
  }
  @media (max-width: 920px) {
    justify-self: unset;
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
  @media (max-width: 720px) {
    padding: 8px;
  }
`
