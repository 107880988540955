import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const MilestoneTextLeft: React.FC = props => {
  const { year, title, text, link, image, i } = props as any
  return (
    <Container>
      <ContentWrapper>
        <Year>{year}</Year>
        <Title dangerouslySetInnerHTML={{ __html: title.html }} />
        <Text dangerouslySetInnerHTML={{ __html: text.html }} />
      </ContentWrapper>
      <ImageContainer>
        {/*// @ts-ignore*/}
        {image.fluid ? <Img fluid={image.fluid} /> : null}
      </ImageContainer>
    </Container>
  )
}

export default MilestoneTextLeft

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`
const ContentWrapper = styled.div`
  position: relative;
  justify-self: flex-end;
  padding: 80px 40px 80px 8px;
  text-align: right;
  max-width: 600px;
  @media (max-width: 720px) {
    padding: 8px;
    text-align: center;
    max-width: 100%;
  }
`

const Title = styled.div`
  margin-top: 1rem;
  font-family: "Moderat Bold", sans-serif;
  text-transform: capitalize;
  color: var(--primary-text);
`
const Text = styled.div`
  p {
    color: var(--primary-text);
    margin-bottom: 1rem;
  }
`
export const Year = styled.span`
  font-family: "Canela Light", sans-serif;
  padding: 16px 0;
  background: white;
  color: var(--primary-text);
  font-size: 30px;
  position: absolute;
  top: 0;
  right: -56px;
  width: 100px;
  text-align: center;
  z-index: 1;
  @media (max-width: 720px) {
    position: static;
    display: block;
    margin: 0 auto;
  }
`

export const ImageContainer = styled.div`
  padding: 80px 8px 80px 40px;
  border-left: 1px solid var(--primary-text);
  .gatsby-image-wrapper {
    width: 400px;
    height: 250px;
    @media (max-width: 720px) {
      width: unset;
    }
  }
  @media (max-width: 920px) {
    width: 300px;
  }
  @media (max-width: 720px) {
    display: none;
  }
`
