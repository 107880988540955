import React from "react"
import styled from "styled-components"

import MilestoneTextLeft from "./milestoneTextLeft"
import MilestoneTextRight from "./milestoneTextRight"

import useWindowSize from "../../hooks/utils/useWindowSize"

const Milestone: React.FC = props => {
  const { i } = props as any
  const isEven = i % 2 === 0
  const { width } = useWindowSize()

  if (width <= 720) {
    return (
      <>
        <StyledLine />
        <MilestoneTextLeft {...props} />
        <StyledLine />
      </>
    )
  }
  return (
    <>
      <Container>
        {isEven ? (
          <>
            <MilestoneTextLeft {...props} />
            <StyledLine />
          </>
        ) : (
          <>
            <MilestoneTextRight {...props} />
            <StyledLine />
          </>
        )}
      </Container>
    </>
  )
}

export default Milestone

const Container = styled.div`
  position: relative;
`

const StyledLine = styled.div`
  width: 1px;
  margin: 0 auto;
  background: var(--primary-text);
  height: 100px;
  @media (min-width: 720px) {
    display: none;
  }
`
