import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../components/layout/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import Milestone from "../components/about/milestone"

const About: React.FC<{ data: any }> = ({ data }) => {
  const { uid, data: aboutData } = data.prismicAboutUsPage
  const bannerData = aboutData.body.find(
    slice => slice.slice_type === "book"
  ).primary
  const pageHeaderData = aboutData.body.find(
    slice => slice.slice_type === "header"
  )
  const milestonesData = aboutData.body.filter(
    slice => slice.slice_type === "milestone"
  )

  return (
    <Layout bookBannerData={bannerData}>
      <SEO title={uid} />
      <Container>
        <PageHeader {...pageHeaderData.primary} />
        <MilestonesContainer>
          <Line />
          {milestonesData.map((milestone, i) => (
            <Milestone key={i} {...{ ...milestone.primary, i }} />
          ))}
          <Line />
        </MilestonesContainer>
      </Container>
    </Layout>
  )
}

export default withPreview(About)

const Container = styled.div`
  padding: 0 0 40px 0;
`

export const Line = styled.div`
  width: 1px;
  margin: 0 auto;
  background: var(--primary-text);
  height: 100px;
  left: 1px;
  position: relative;
`
const MilestonesContainer = styled.div``
export const query = graphql`
  query {
    prismicAboutUsPage {
      uid
      data {
        body {
          ... on PrismicAboutUsPageBodyHeader {
            id
            slice_type
            primary {
              title {
                html
              }
              text {
                html
                text
              }
              tagline {
                html
                text
              }
            }
          }
          ... on PrismicAboutUsPageBodyMilestone {
            id
            slice_type
            primary {
              year
              title {
                html
              }
              text {
                text
                html
              }
              image {
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
              link {
                url
                link_type
              }
            }
          }
          ... on PrismicAboutUsPageBodyBook {
            id
            slice_type
            primary {
              button_text
              text {
                text
              }
              link {
                url
                link_type
              }
            }
          }
        }
      }
      _previewable
    }
  }
`
